.review-page-container {
  padding: 20px;
  position: relative;

  > div:first-child {
    top: 40px;
    position: sticky;
    height: max-content;
  }

  .category-breadcrumb {
    margin-bottom: 30px;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
  }

  h4 {
    font-size: 16px;
    font-weight: 600;
  }

  .customer-reviews {
    > div {
      &.row {
        padding-bottom: 30px;
        display: block;
      }
    }
  }

  .submit-btn {
    font-weight: 600;

    button {
      padding: 10px 15px;
    }
  }
}

.customer-rating {
  .rating-container {
    padding: 30px 0;
  }

  a {
    color: $link-color;
  }

  a:hover {
    text-decoration: none;
  }

  .col-lg-6:first-child {
    border-right: 1px solid $border-common;
  }

  .rating-bar {
    top: 12px;
    padding: 0px;
    height: 5px;
    position: relative;
    background-color: $light-background;

    > div {
      width: 0px;
      height: 100%;
      background-color: $black-color;
    }
  }

  .theme-btn {
    &.light {
      margin-top: 10px;
    }
  }
}

.review-form {
  width: 80%;

  > div {
    padding-top: 30px;

    label {
      font-size: 14px;
      font-weight: 500;
      display: block;
    }

    input,
    textarea {
      width: 100%;
      resize: none;
      font-size: 16px;
      padding: 5px 16px;
      border-radius: 1px;
      border: 1px solid $border-common;
    }
  }
}

.filters-container {
  margin: 20px 0;

  .toolbar-wrapper {
    > div {
      margin: 0 20px 0 0;
      display: inline-block;

      label {
        font-weight: 500;
        margin-right: 10px;
      }

      select {
        cursor: pointer;
        padding: 6px 16px;
        color: $font-color;
        background-color: white;
      }

      .down-icon-position {
        pointer-events: none;
        background-color: $white-color;
      }
    }

    > div:not(:first-child) {
      vertical-align: super;
    }

    .limiter::after {
      margin-left: 10px;
    }
  }
}

.view-mode {
  margin-bottom: 20px;

  .rango-view-grid-container {
    width: 36px;
    height: 36px;
    cursor: pointer;
    color: $font-color;
    padding: 6px 0 0 5px;
    display: inline-block;

    &.active {
      color: white;
      background-color: $theme-color;
    }
  }

  .rango-view-list-container {
    width: 36px;
    height: 36px;
    cursor: pointer;
    color: $font-color;
    padding: 6px 0 0 5px;
    display: inline-block;

    &.active {
      color: white;
      background-color: $theme-color;
    }
  }
}

.modal-container {
  left: 50%;
  top: 100px;
  z-index: 11;
  width: 600px;
  max-width: 80%;
  max-height: 80%;
  position: fixed;
  font-size: 14px;
  overflow-y: auto;
  margin-left: -300px;
  background: #ffffff;
  animation: jelly 0.5s ease-in-out;
  animation: fade-in-white 0.3s ease-in-out;

  @include border-radius(5px);
  @include box-shadow(0px 15px 25px 0px rgba(0, 0, 0, 0.03), 0px 20px 45px 5px rgba(0, 0, 0, 0.2));

  .modal-header {
    h3 {
      display: inline-block;
      font-size: 20px;
      color: $font-color;
      margin: 0;
    }

    .icon {
      cursor: pointer;

      &.remove-icon {
        width: 24px;
        height: 24px;
        background-image: url("../images/Icon-remove.svg");
      }
    }
  }

  .modal-body {
    padding: 20px;

    .control-group .control {
      width: 100%;
    }
  }
}

.product-card-new {
  width: 12rem;
  border: none !important;
  margin: 0 5px 10px 10px;

  .category-product-image-container {
    margin: 0 auto;
    height: 190px;
    position: relative;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .product-image-container {
    max-height: 190px;
    position: relative;

    img {
      width: 100%;
      min-height: 190px;
      max-height: 190px;
    }
  }

  .card-current-price {
    font-size: 18px;
  }

  .product-rating {
    @extend .text-nowrap;

    .stars {
      display: inline-block;
    }

    span {
      font-size: 14px;
      vertical-align: middle;
    }

    .material-icons {
      font-size: 16px;
    }
  }

  .card-body {
    cursor: default;

    > div:last-child {
      margin-top: 10px;
    }

    .product-name,
    .product-rating {
      width: 15rem;
      margin-bottom: 2px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .product-price {
      margin-bottom: 15px;
    }
  }

  .sticker {
    display: block;
  }

  .card-body {
    .compare-icon,
    .wishlist-icon {
      left: 0;
      top: 10px;
      display: none;
      margin-left: 5px;
      margin-right: 5px;
      position: absolute;
    }

    .compare-icon {
      right: 0;
      left: unset;
    }

    .add-to-cart-btn {
      width: 100%;
      position: relative;

      .btn-add-to-cart {
        width: 100%;
        max-width: 140px;
        max-width: 100% !important;

        &.small-padding {
          max-width: 130px;
        }
      }
    }
  }
}

.quick-view-btn-container {
  left: -20px;
  width: 100%;
  bottom: 10px;
  display: none;
  position: absolute;

  span {
    left: 32%;
    top: -28px;
    z-index: 1;
    font-size: 16px;
    color: #ffffff;
    position: absolute;
  }

  button {
    left: 30%;
    top: -36px;
    border: none;
    color: white;
    font-size: 16px;
    padding: 5px 10px 7px 24px;
    position: absolute;
    opacity: 0.8;
    background-color: #0d2438;
  }
}

.product-card-new:hover {
  #quick-view-btn-container {
    display: block;
  }

  .category-product-image-container,
  .product-image-container {
    overflow: hidden;

    img {
      transition: 0.5s all;
      transform: scale(1.05);
    }
  }

  .compare-icon,
  .wishlist-icon {
    display: block;
  }

  .sticker {
    display: none;
  }
}

.lg-card-container:hover {
  .product-image {
    overflow: hidden;

    img {
      transition: 0.5s all;
      transform: scale(1.05);
    }
  }
}

.quantity {
  @extend .btn-group;

  label {
    float: left;
    padding: 5px 15px 10px 0;
  }

  .input-btn-group {
    button {
      border: 1px solid #dcdcdc;
      padding: 7px;
      background: transparent;
      text-align: center;
      height: 38px;

      &.decrease {
        border-right: 0;
      }

      &.increase {
        border-left: 0;
      }

      &:hover,
      &:active,
      &:focus {
        outline: none;
      }

      .rango-plus,
      .rango-minus {
        font-size: 20px;
        vertical-align: middle;
      }
    }

    input {
      border: 1px solid #dcdcdc;
      border-right: 0;
      border-left: 0;
      max-width: 50px;
      height: 38px;
      margin-left: -5px;
      margin-right: -5px;
      vertical-align: top;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
    }
  }

  &.has-error {
    button {
      border-color: #fc6868;
      color: #fc6868;
    }
  }

  .control-error {
    display: block;
  }
}

.form-container {
  .container {
    width: 65%;
    margin: 0 auto;
    padding-top: 30px;

    .heading {
      width: 100%;
      margin-bottom: 28px;
      display: inline-block;

      h2 {
        line-height: 4rem;
        display: inline-block;
      }

      .btn-new-customer {
        float: right;
        font-size: 16px;
      }
    }

    .body {
      font-size: 16px;
      padding: 35px 55px;
      margin-bottom: 60px;
      border: 1px solid $border-common;

      .form-header {
        margin-bottom: 20px;
      }

      form {
        > div {
          padding-bottom: 20px;
        }
      }
    }
  }

  .back-button {
    float: right;
  }
}

.container-right {
  > .recently-viewed {
    padding-top: 20px;
  }
}

.rango-star {
  cursor: default;
}

.customer-options {
  top: 40px;
  float: right;
  padding: 20px;
  width: 200px !important;

  .customer-session {
    padding: 10px 20px 0 20px;

    label {
      font-size: 18px;
      color: $grey-color;
      text-transform: uppercase;
    }
  }

  li {
    padding: 3px 0;
    height: unset !important;

    a {
      display: block;
      padding: 0 20px !important;
    }
  }

  a {
    font-size: 16px;
  }
}

.cart-btn-collection {
  button[type="button"].btn-secondary {
    border: none;
    font-size: 16px;
    color: $black-color;
    background-color: $white-color;

    :hover {
      color: $black-color !important;
      background-color: $white-color !important;
    }

    :focus,
    :active {
      outline: none;
      box-shadow: none;
    }

    #cart-count {
      left: -20px;
      top: -15px;
      padding: 4px;
      min-width: 20px;
      border-radius: 50%;
      position: relative;
      color: $white-color;
      background: $button-primary-bg;
    }
  }
}

.dropdown-icon-custom::after {
  top: -5px;
  color: black;
  font-size: 16px;
  position: relative;
  display: inline-block;
  margin-left: 1rem;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

#cart-modal-content {
  top: 40px;
  z-index: 100;
  width: 350px;
  right: 0;
  position: absolute;
  border-top: 4px solid #26a37c;

  .close {
    top: 12px;
    right: 15px;
    padding: 0px;
    position: relative;
  }

  .mini-cart-container {
    height: 100%;
    width: 100%;
    font-size: 14px;
    max-height: 200px;
    overflow-y: scroll;
    padding: 10px 15px 0 20px;
  }

  .small-card-container {
    margin: 0;
    width: 100%;
    padding: 0;

    .product-image-container {
      margin: 10px 10px 10px 0px;
      border: 1px solid $border-light;
    }

    label {
      float: left;
      margin-top: 7px;
    }

    input {
      width: 40px;
      height: 36px;
      text-align: center;
      font-weight: 500;
      border: 1px solid $border-light;
    }

    .card-total-price {
      float: right;
    }

    .remove-item {
      top: -10px;
      left: -10px;
      border-radius: 50%;
      position: absolute;
      background: #111111;
      color: #ffffff;
      padding: 0px 4px;

      .rango-close {
        font-size: 12px;
        font-weight: 600;
        padding: 0;
      }
    }
  }

  .small-card-container:hover {
    box-shadow: none;
  }

  .modal-footer {
    padding-right: 15px;
  }
}

.cart-details {
  padding: 40px 0px;

  h1 {
    margin-bottom: 30px;
  }

  h2 {
    margin-bottom: 25px;
  }

  .cart-details-header {
    .cart-header {
      max-height: 45px;
      margin-bottom: 20px;
      padding-bottom: 20px !important;
      border-bottom: 2px solid $border-general;

      > h3 {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .cart-content {
    padding: 0;

    .product-quantity {
      .quantity {
        display: inline-block;
        width: unset;
        float: right;

        label {
          display: none !important;
        }
      }
    }

    .destop-cart-view {
      display: block;
    }

    .mobile-view {
      display: none;
    }

    .cart-item-list {
      > .row {
        margin-bottom: 40px;
      }

      > .row:last-child {
        padding-bottom: 20px;
        border-bottom: 2px solid $border-general;
        margin-bottom: 20px;
      }

      .product-image-container {
        padding: 0;
        max-width: 110px;
      }

      .wishlist-icon {
        margin: 0;
        display: inline;
      }
    }

    .product-details-content {
      padding-left: 20px;

      .row {
        font-size: 16px;

        .card-current-price {
          font-size: 18px;
        }

        > a {
          line-height: 20px;
        }
      }

      .item-price {
        margin-top: 12px !important;
        font-size: 18px;
        font-weight: 600;
      }

      .item-actions {
        margin-top: 12px !important;

        .d-inline-block {
          float: left;

          &:first-child {
            margin-right: 30px;
          }

          .material-icons {
            float: left;
            margin-left: -2px;
            margin-right: 5px;
          }

          .rango-delete {
            margin-left: -2px;
          }
        }
      }
    }

    .product-quantity {
      .quantity {
        position: relative;
        top: -8px;
      }
    }

    .misc {
      display: flex;
      justify-content: space-between;
    }
  }

  .continue-shopping-btn {
    @extend .theme-btn;

    max-width: 156px;
    margin-top: 20px;
    margin-left: 15px;
  }

  .coupon-container {
    margin-top: 20px;

    .control-error {
      padding: 10px 0;
    }
  }
}

@media only screen and (max-width: 375px) {
  .cart-details {
    .cart-content {
      .misc {
        flex-direction: column;

        button {
          margin-top: 10px;
          width: 100%;
        }
      }
    }
  }
}

.account-content {
  //min-height: 100vh;

  ol.breadcrumb {
    margin: 0 0 2 0;
    padding: 0;
    list-style: none;
    background-color: transparent;

    li.breadcrumb-item {
      display: inline-block;

      & + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 5px;
        padding-left: 5px;
        content: "/";
      }
    }
  }

  .sidebar {
    height: 100%;
    border-right: 1px solid $border-general;

    .customer-sidebar {
      .account-details {
        text-align: center;
        padding: 25px 20px;

        .customer-name {
          width: 60px;
          height: 60px;
          margin: 0 auto;
          font-size: 24px;
          margin-bottom: 5px;
          display: inline-block;
        }

        .customer-name-text {
          color: $font-color;
        }

        .customer-email {
          color: $grey-color;
        }
      }

      .navigation {
        width: 100%;

        li {
          width: 100%;

          &:hover,
          &.active {
            color: $light-link-color;
            background-color: $border-light;
          }

          i {
            &.icon {
              @extend .rango-default;

              font-size: 18px;
              padding-right: 5px;

              &.profile::before {
                content: "\E995";
              }

              &.address::before {
                content: "\e949";
              }

              &.reviews::before {
                content: "\e97d";
              }

              &.release::before {
                content: "\e97d";
              }

              &.wishlist::before {
                content: "\e93e";
              }

              &.orders::before {
                content: "\e931";
              }

              &.downloadables::before {
                content: "\e926";
              }

              &.compare::before {
                content: "\e93b";
              }
            }
          }

          a {
            display: block;
            padding: 10px 15px;
          }
        }

        li:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .account-layout {
    color: $font-color;
    padding: 15px 20px;
    padding-bottom: 60px;

    .account-table-content.profile-page-content {
      .table {
        width: 100% !important;
      }
    }

    .table {
      table {
        tr {
          border: 1px solid #cccccc;
          height: auto !important;

          td {
            width: auto;
            border-top: none;
            border-right: 1px solid #cccccc !important;
          }
        }
      }
    }

    &.right {
      padding-left: $sidebar-width + 20px !important;
    }

    .account-head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .account-heading {
      font-size: 24px;
      font-weight: 600;
    }

    .account-table-content {
      > .row,
      .control-group {
        margin-bottom: 30px;
      }

      label {
        font-weight: 500;
      }

      input,
      select,
      textarea {
        width: 100%;
        resize: none;
        font-size: 16px;
        padding: 5px 16px;
        border-radius: 1px;
        background: $button-text-color;
        border: 1px solid $border-common;
      }

      input[type="search"] {
        padding-left: 35px;
      }

      input:active,
      input:focus,
      textarea:active,
      textarea:focus,
      select:focus,
      select:active {
        border-color: $theme-color;
      }

      .address-holder {
        margin-top: 30px;

        > div {
          margin: 5px 0;
          padding-left: 0;
        }

        .card {
          height: 100%;
        }
      }

      .account-items-list {
        margin-bottom: 40px;
      }

      &.profile-page-content {
        .table {
          padding: 0;
          width: 800px;
          margin-bottom: 15px;
        }

        .table > table {
          width: 100%;
          color: #5e5e5e;
          border: 1px solid rgba(0, 0, 0, 0.125);
        }

        .table td {
          border: unset;
          padding: 6px 12px;
        }
      }

      .image-wrapper {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px;
        display: inline-block;

        .image-item {
          width: 200px;
          height: 200px;
          position: relative;
          border-radius: 3px;
          margin-right: 20px;
          background: #f8f9fa;
          margin-bottom: 20px;
          display: inline-block;
          background-position: 50%;
          background-repeat: no-repeat;
          background-image: url(/vendor/webkul/ui/assets/images/placeholder-icon.svg);

          .remove-image {
            left: 0;
            bottom: 0;
            width: 100%;
            color: #fff;
            padding: 10px;
            cursor: pointer;
            margin-bottom: 0;
            text-align: center;
            position: absolute;
            margin-right: 20px;
            border-radius: 0 0 4px 4px;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.24);
            background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.24));
          }

          input {
            display: none;
          }

          img.preview {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .account-items-list {
      &.wishlist-container {
        width: 100%;
        margin: 0 auto;

        .product-card-new {
          width: 19rem;
        }
      }
    }

    .reviews-container {
      > .row {
        margin-bottom: 40px;
      }
    }

    .bottom-toolbar {
      .pagination {
        margin: 0;

        a:not([href]) {
          &.next,
          &.previous {
            cursor: not-allowed;
            color: $grey-color !important;
          }
        }

        .page-item {
          @extend .theme-btn;
          @extend .light;
          @extend .unset;
          @extend .fs16;

          border: none !important;
          box-shadow: unset !important;
          -webkit-box-shadow: unset !important;

          &.active {
            border: 1px solid $theme-color;
            color: $theme-color !important;
          }

          &.next,
          &.previous {
            .angle-right-icon,
            .angle-left-icon {
              @extend .rango-default;

              margin: 0;
              font-size: 24px;
              background: unset;
              text-align: center;
            }
          }

          &.next {
            .angle-right-icon::before {
              content: "\E908";
            }
          }

          &.previous {
            .angle-left-icon::before {
              content: "\E907";
            }
          }
        }
      }
    }

    .sale-container {
      font-size: 16px;

      .tabs {
        ul {
          font-weight: 600;
          font-size: 20px;
          list-style-type: none;

          li {
            cursor: pointer;
            padding: 10px 15px;
            display: inline-block;
            border-bottom: 2px solid transparent;

            &.active {
              cursor: default;
              border-bottom: 2px solid $theme-color;
            }
          }
        }
      }

      .tabs-content {
        .sale-section {
          padding: 20px 0 10px 0;
          border-bottom: 1px solid $border-common;

          .section-title {
            font-size: 18px;
            font-weight: 600;
            padding-bottom: 10px;
            color: $grey-color;
          }

          .section-content {
            label + span {
              font-weight: 600;
              color: $grey-color;
            }

            .totals {
              width: 100%;
              display: inline-block;

              .sale-summary {
                float: right;

                tbody {
                  tr {
                    td:first-child {
                      width: 200px;
                    }
                  }
                }
              }
            }

            .table {
              table {
                width: 100%;
              }
            }
          }
        }
      }

      .order-box-container {
        padding: 10px 0;

        .box {
          width: calc(25% - 5px);
          vertical-align: top;
          display: inline-block;

          .box-title {
            font-size: 18px;
            padding: 10px 0;
            font-weight: 600;
            color: $grey-color;
          }
        }
      }
    }
  }

  .select-icon {
    left: 95%;
    top: -28px;
    font-size: 22px;
    position: relative;
  }
}

#alert-container {
  top: 50px;
  right: 15px;
  z-index: 100;
  position: fixed;
  font-size: 16px;

  .alert {
    max-width: 400px !important;
    min-height: 45px !important;
    max-height: 100px !important;

    &.alert-dismissible {
      .close {
        font-size: 23px;
        padding: 0.3rem 1.25rem;
      }
    }
  }
}

.wishlist-icon {
  vertical-align: middle;

  i {
    color: $black-color;
  }
}

.checkout-process {
  padding: 40px 20px;

  .accordian-header {
    h3 {
      margin-bottom: 0 !important;
    }
  }

  .coupon-container {
    margin-top: 20px;
  }

  h1 {
    font-weight: 600;
    margin-bottom: 30px;
  }

  .scrollable {
    padding-top: 25px;
  }

  .order-summary-container {
    top: 75px;

    .theme-btn {
      display: none;
    }

    &.bottom {
      h3 {
        display: none;
      }
    }
  }

  input[type="radio"] {
    transform: scale(1.3);
    -ms-transform: scale(1.3);
    -webkit-transform: scale(1.3);
  }

  .styled-select {
    cursor: pointer;

    + .select-icon {
      top: 55%;
      left: 92%;
      font-size: 20px;
      position: absolute;
    }
  }

  .coupon-container {
    input {
      max-width: 200px;
    }

    button {
      margin: 20px 0 30px 0;
    }

    .applied-coupon-details {
      font-size: 16px;
      margin-bottom: 10px;
    }

    .applied-coupon-details label:nth-of-type(1) {
      color: $theme-color;
    }

    .rango-close {
      cursor: pointer;
      margin-left: 5px;
    }
  }
}

.address-container {
  .address-holder {
    margin-top: 15px;

    > div {
      margin: 5px 0;
      padding-left: 0;
    }

    .card {
      height: 100%;

      h5 {
        font-size: 14px;
      }

      .add-address-button {
        height: 100%;
        display: table;
        text-align: center;

        > div {
          display: table-cell;
          vertical-align: middle;

          span {
            vertical-align: super;
          }
        }
      }
    }
  }
}

.custom-form {
  .form-field {
    padding: 0;
    margin-bottom: 30px;
  }

  label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  input[type="text"],
  input[type="password"],
  input[type="search"],
  select {
    width: 100%;
    resize: none;
    font-size: 16px;
    height: 36px;
    padding: 5px 16px;
    border-radius: 1px;
    background: $button-text-color;
    border: 1px solid $border-common;
  }

  input[type="checkbox"] {
    position: relative;
    top: 3px;
  }

  input:active,
  input:focus,
  select:focus,
  select:active {
    border-color: $theme-color;
  }
}

.shipping-form,
.payment-form,
.review-checkout-conainer {
  h3 {
    margin-bottom: 20px;
  }

  .shipping-methods,
  .payment-methods {
    > .row {
      margin-bottom: 20px;
    }

    .instructions {
      margin-top: 5px;
      margin-left: -13px;

      label {
        font-weight: 600;
        font-size: 14px;
      }

      p {
        margin: 0;
        font-size: 14px;
        color: #777777;
        font-style: italic;
      }
    }
  }

  .address-summary {
    li {
      display: inline-block;
    }
  }

  .cart-item-list {
    padding: 20px 0;
    border-bottom: 1px solid $border-general;

    h4 {
      padding-bottom: 20px;
      border-bottom: 1px solid $border-general;
      margin-bottom: 20px !important;
    }

    > .row:first-child {
      margin-top: 50px;
    }

    > .row {
      margin-bottom: 20px;
    }
  }

  .cart-details {
    padding: 40px 0;
  }
}

.order-summary-container {
  top: 50px;
  padding-top: 25px;
  height: max-content;
  position: sticky !important;
  max-width: 500px !important;

  > div {
    width: 100%;
  }

  .order-summary {
    padding: 25px 30px;
    border: 1px solid $border-general;

    > h3 {
      margin-bottom: 20px;
    }

    > .row:not(:last-child) {
      margin-bottom: 10px;
    }

    #grand-total-detail {
      margin-top: 15px;
      padding-top: 15px;
      margin-bottom: 25px;
      border-top: 1px solid $border-general;
    }
  }
}

.order-success-content {
  padding: 40px 20px;
  font-size: 16px;
}

.search-result-status {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#address-section {
  .form-header {
    h3 {
      margin-bottom: 20px;
    }
  }
}

.attached-products-wrapper {
  margin-top: 20px;
}

#related-products-carousel {
  .product-card-new:first-child {
    margin-left: 0px !important;
  }
}

.price-label {
  margin-right: 6px;
}

.product-price {
  display: inline-block;

  .price-label {
    margin-right: 6px;
  }

  .regular-price {
    font-size: 14px;
    font-weight: 500;
    text-decoration: line-through;
    display: inline-block;
  }

  .special-price {
    display: inline-block;
    margin-right: 10px;
    float: left;
  }

  /*
       for bundle product content is more so adjusting in particular div
       by using default behaviour i.e. inline
    */
  .price-from {
    .bundle-regular-price {
      font-size: 12px !important;
      font-weight: 500;
      margin-right: 10px;
      text-decoration: line-through;
    }

    .bundle-special-price {
      font-size: 15px !important;
      font-weight: 600;
    }

    .bundle-to {
      display: block;
      font-size: 15px !important;
      font-weight: 500;
      margin-top: 1px;
      margin-bottom: 1px;
    }
  }

  span {
    &.price-label {
      font-size: 14px !important;
      font-weight: 500 !important;
    }

    &.final-price {
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.sticker {
  top: 8px;
  left: 8px;
  border: none;
  color: white;
  display: none;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 10px;
  position: absolute;
  border-radius: 12px;

  &.sale {
    padding: 2px 14px;
    background-color: $danger-color;
  }

  &.new {
    background-color: $theme-color;
    display: block;
  }
}

#app {
  position: relative;
}

.main-container-wrapper {
  /**
     * Sticky header for logo and search component. Just make sure
     * this class should have height otherwise CLS score will reduce.
     */
  .sticky-header {
    top: 0px;
    height: 56px;
    z-index: 100;
    position: sticky;
    background: white;

    &.header-shadow {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
  }
}

.search-container {
  padding: 30px 20px;

  .lg-card-container {
    &.list-card {
      margin: 0 15px;
    }
  }

  :first-child {
    margin-top: 0px;
  }
}

.method-sticker {
  font-size: 13px;
  padding: 4px 8px;
  margin-right: 3px;
  margin-bottom: 3px;
  text-align: center;
  border-radius: 1px;
  display: inline-block;
  color: $light2-black;
  background-color: $light1-black;
}

.sidebar {
  z-index: 1000000;
  width: $sidebar-width;

  .category-content {
    .category-title {
      top: -1px;
      font-weight: 600;
      position: relative;
    }

    .rango-arrow-right {
      top: 4px;
      position: relative;
    }

    .category-icon {
      width: 25px;
      height: 20px;
      padding-right: 5px;
      display: inline-block;

      img {
        width: 100%;
        height: 100%;
        vertical-align: text-top;
      }
    }
  }

  li:hover {
    > a {
      > span {
        color: $light-link-color;
      }
    }
  }

  .sub-categories {
    display: none;

    .category {
      padding: 5px 0 4px 15px;

      + .nested {
        color: $font-color;

        li {
          a {
            padding-top: 0;

            .category-title {
              font-weight: 500;
              padding-left: 28px;
            }
          }
        }
      }

      .category-title {
        vertical-align: top;
      }
    }
  }
}

.category-list-container {
  z-index: 10;
  padding: 0px !important;
  background: $white-color;
  position: absolute !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

  .category {
    @extend .cursor-pointer;

    width: 100%;
    line-height: 2.5rem;
    display: inline-block;

    span {
      top: -4px;
      position: relative;
    }
  }

  li {
    a {
      padding: 7px 0 5px 15px;

      &:hover {
        background: $border-light;
      }
    }
  }

  .sub-categories {
    top: -1px;
    left: 100%;
    height: 100%;
    min-height: 330px;
    z-index: 100;
    padding-top: 10px;
    position: absolute;
    background: $white-color;
    border-left: 1px solid $border-common;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    overflow-y: scroll;

    li:nth-last-of-type(1) {
      margin-bottom: 10px;
    }
  }
}

#sidebar-level-0 {
  display: none;
  z-index: 100000;
  border-top: 1px solid $border-common;
}

.grouped-product-container {
  .grouped-product-list {
    ul {
      li {
        width: 100%;
        font-size: 18px;
        margin-bottom: 10px;
        display: inline-block;

        &:last-child {
          margin-bottom: 0;
        }

        &:first-child {
          span {
            font-weight: 600;

            &:last-child {
              float: right;
              width: 50px;
              text-align: left;
            }
          }
        }

        .name {
          font-size: 16px;
          vertical-align: middle;
          display: inline-block;
        }

        .qty {
          float: right;

          .control-group {
            height: 45px;
            width: auto;
            border-top: 0;
            padding-top: 0;
            margin-bottom: 0;
            max-width: initial;
            text-align: center;

            label {
              display: none;
            }

            .control {
              width: 60px;
              text-align: center;
              line-height: 38px;
            }

            > * {
              height: 100%;
            }
          }
        }
      }
    }
  }
}

.bundle-options-wrapper {
  .bundle-option-list {
    padding: 15px 0;
    border-top: solid 1px rgba(162, 162, 162, 0.2);

    h3 {
      font-size: 16px;
      margin: 0;
      color: #242424;
    }

    .bundle-option-item {
      border-bottom: solid 1px rgba(162, 162, 162, 0.2);
      padding: 15px 0;
      width: 100%;
      display: inline-block;

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }

      .control-group {
        margin-bottom: 0;
        color: #5e5e5e;

        label {
          color: #242424;
        }

        .control {
          color: #5e5e5e;
        }
      }

      .quantity {
        border-top: 0;
      }

      .control-error {
        float: left;
        width: 100%;
      }

      &.has-error {
        button {
          border-color: #fc6868;
          color: #fc6868;
        }
      }
    }
  }

  .bundle-summary {
    padding: 15px 0;
    border-top: solid 1px rgba(162, 162, 162, 0.2);

    h3 {
      font-size: 16px;
      margin: 0;
      color: #242424;
    }

    .quantity {
      border-top: 0;
    }

    .bundle-price {
      font-weight: 600;
      font-size: 24px;
      color: #ff6472;
      margin-top: 10px;
    }

    ul.bundle-items {
      li {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .selected-products {
          color: #5e5e5e;
        }
      }
    }
  }
}

.category-container,
.search-container {
  .grid-card {
    width: 15rem;
  }
}

.downloadable-container {
  .sample-list {
    padding: 5px 0;

    h3 {
      font-size: 16px;
      margin-top: 0;
    }

    ul {
      li {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .link-list {
    padding: 5px 0;

    h3 {
      font-size: 16px;
      margin-top: 0;

      &.required:after {
        content: "*";
        font-size: 16px;
        margin-left: -1px;
        color: #f05153;
      }
    }

    ul {
      li {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        .checkbox {
          input[type="checkbox"] {
            width: 15px !important;
            height: 15px !important;
            margin-left: -10px;
          }
        }

        a {
          float: right;
          margin-top: 3px;
        }
      }
    }
  }
}

.category-container {
  min-height: 670px;
  margin: 0 auto;
  padding: 40px 15px !important;

  .hero-image {
    display: inline-block;

    img {
      width: 100%;
      height: 100%;
      max-height: 500px;
      margin-bottom: 30px;
    }
  }
}

.vue-slider {
  .vue-slider-rail {
    background-color: #ccc;
  }

  .vue-slider-dot-handle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $white-color;
    box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);
  }

  .vue-slider-dot-tooltip-inner {
    border-color: $theme-color !important;
    background-color: $theme-color !important;
  }

  .vue-slider-dot-tooltip-text {
    display: block;
    font-size: 14px;
    min-width: 20px;
    padding: 2px 5px;
    text-align: center;
    border-radius: 5px;
    white-space: nowrap;
    color: $white-color;
    border-color: $theme-color !important;
    background-color: $theme-color !important;
  }

  .vue-slider-dot-tooltip-text::before {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border: 6px solid transparent\0;
    border-top-color: inherit;
    transform: translate(-50%);
  }

  .vue-slider-process {
    background-color: $theme-color !important;
  }
}

.full-content-wrapper {
  > .container-fluid {
    padding: 0;
    margin: 10px 0;

    > .row {
      padding: 0 15px;
    }
  }

  p,
  div {
    > .container-fluid {
      padding: 0;
      margin: 10px 0;

      > .row {
        padding: 0 15px;
      }
    }
  }
}

.slides-container {
  position: relative;

  .VueCarousel-pagination {
    display: none;

    button:focus,
    button:active {
      outline: none;
      box-shadow: none;
    }

    .VueCarousel-dot {
      padding: 5px !important;
    }
  }

  .VueCarousel-dot--active {
    background-color: $theme-color !important;
  }

  .VueCarousel {
    .VueCarousel-inner {
      padding-top: 0;
    }

    .VueCarousel-slide {
      position: relative;

      .show-content {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: table;
        text-align: center;
        position: absolute;

        p {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }
}

.filter-attributes-item {
  margin-bottom: 10px;
  border-bottom: 1px solid #cccccc;

  &.active {
    .filter-attributes-content {
      display: block;
    }
  }

  .filter-input {
    margin: 10px 15px 13px -4px;

    input[type="text"] {
      text-align: center;
      border: 1px solid $theme-color;
      width: 30%;
      background-color: white;
    }
  }

  input[type="checkbox"] + span {
    margin-left: 10px !important;
  }
}

.filter-attributes-content {
  display: none;
  margin-left: 7px;
}

.layered-filter-wrapper {
  @extend .scrollable;

  max-height: 670px;
  overflow-x: hidden;
  margin-bottom: 42px;
  padding: 42px 10px 0 10px;

  .recently-viewed {
    margin-top: 20px;

    h2 {
      font-size: 18px;
    }
  }
}

.selective-div {
  width: 150px;
  -webkit-appearance: none;
}

.select-icon-margin {
  margin-top: 10px;
  margin-left: 96px;
}

.down-icon-position {
  position: absolute;
}

.select-icon-show-margin {
  margin-left: 35px;
  margin-top: 10px;
}

.down-arrow-margin {
  margin-left: 75px;
  margin-top: 8px;
}

.vc-header {
  z-index: 10;
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
}

.new-products-recent {
  top: -44px;
  position: relative;
}

.recently-viewed-products-wrapper {
  padding: 2px;

  .price-from {
    .bundle-regular-price {
      display: none;
    }

    .bundle-special-price {
      font-size: 15px !important;
      font-weight: 600;
    }

    .bundle-to {
      display: unset;
      margin: 0 2px;
      font-size: 15px !important;
      font-weight: 500;
    }
  }
}

.category-page-wrapper {
  margin: 0 auto;
}

.pagination {
  width: 100%;
  justify-content: center;

  .page-item {
    padding: 0 5px;

    &.active {
      font-weight: 600;
      color: $theme-color !important;
      border-bottom: 2px solid $theme-color;
    }

    &.next,
    &.previous {
      .angle-right-icon,
      .angle-left-icon {
        @extend .rango-default;

        margin: 0;
        font-size: 24px;
        background: unset;
        text-align: center;
      }
    }

    &.next {
      .angle-right-icon::before {
        content: "\E908";
      }
    }

    &.previous {
      .angle-left-icon::before {
        content: "\E907";
      }
    }
  }

  a {
    color: unset !important;
    text-decoration: none !important;

    i {
      top: 2px;
      font-size: 18px;
      position: relative;
    }
  }

  .angle-left-icon,
  .angle-right-icon {
    @include rango-default;

    background: unset;
  }

  .angle-right-icon::before {
    content: "\E908";
  }

  .angle-left-icon::before {
    content: "\E907";
  }
}

.full-content-wrapper {
  .container-fluid {
    .row.carousel-products-header {
      padding-right: 75px !important;
    }
  }
}

.carousel-products {
  + .recently-viewed {
    top: -40px;
    position: relative;
  }

  &.with-recent-viewed {
    .btn-add-to-cart {
      @extend .small-padding;
    }
  }

  .VueCarousel-slide {
    cursor: default;
  }

  .VueCarousel-navigation {
    position: absolute;
    right: 12px;
    top: -49px;

    .VueCarousel-navigation-button {
      position: unset !important;
      transform: none !important;
      padding: 0 !important;
      margin: 0 !important;

      span {
        font-size: 24px;
      }
    }
  }
}

.vue-slider {
  max-width: 97%;
}

.profile-update-form {
  width: 800px;
}

/**
 * Compare products.
 */
.compare-products {
  width: 100%;
  cursor: pointer;
  overflow-x: auto;
  padding-bottom: 20px;
  word-break: break-word;
  margin-left: 0 !important;
  margin-right: 10px !important;

  .active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
    transform: scale(1);
  }

  tr {
    width: 100%;
  }

  td {
    padding: 15px;
    min-width: 250px;
    max-width: 250px;
    vertical-align: top;
  }

  .header {
    min-width: 150px;
  }

  .image-wrapper {
    width: 100%;
  }

  .stars {
    i {
      font-size: 16px;
    }
  }

  .action {
    position: relative;

    .btn-add-to-cart {
      white-space: pre-wrap;
    }

    .close-btn {
      right: 0;
      top: 6px;
      position: absolute;
      display: inline-block;

      &:hover {
        font-weight: 600;
      }
    }

    .compare-icon {
      display: none;
    }
  }

  .material-icons {
    &.cross {
      top: 5px;
      right: 20px;
      cursor: pointer;
      position: absolute;
    }
  }

  .wishlist-icon {
    top: 5px;
    right: 60px;
    position: absolute;
    display: inline-block;
  }
}

/**
 * Compare container specially for account section.
 */
.compare-container {
  .cart-details {
    padding: unset;

    h2 {
      padding: 0;
    }
  }

  .compare-products .col,
  .compare-products .col-2 {
    max-width: 25%;
  }
}

/* hide scrollbar for chrome, safari and opera */
.compare-products::-webkit-scrollbar {
  display: none;
}

/* hide scrollbar for ie, edge and firefox */
.compare-products {
  /* ie and edge */
  -ms-overflow-style: none;

  /* firefox */
  scrollbar-width: none;
}

/**
 * CSS for loader.
 */
.cp-spinner {
  width: 48px;
  height: 48px;
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  left: calc(50% - 24px);
  margin-top: calc(40% - 24px);
}

.overlay-loader {
  top: 50%;
  left: 50%;
  z-index: 11;
  position: fixed;
  margin-top: -24px;
  margin-left: -24px;
}

@media only screen and (max-width: 720px) {
  .product-quantity {
    .input-btn-group {
      display: flex;
    }
  }
  .cp-spinner {
    left: 50%;
    margin-left: -24px;
    top: 50%;
    margin-top: -24px;
  }
}

@media only screen and (max-width: 425px) {
  .cart-details {
    .cart-content {
      .product-quantity {
        height: fit-content;

        .quantity {
          display: flex;
          width: 100%;
        }
      }

      .product-price.col-1 {
        flex: 0 0 100%;
        max-width: 100%;

        .card-current-price.fw6 {
          float: right;
        }
      }
    }
  }
}

/**
 * Spinner style.
 */
.cp-round:before {
  border-radius: 50%;
  content: " ";
  width: 48px;
  height: 48px;
  display: inline-block;
  box-sizing: border-box;
  border-top: solid 6px grey;
  border-right: solid 6px grey;
  border-bottom: solid 6px grey;
  border-left: solid 6px grey;
  position: absolute;
  top: 0;
  left: 0;
}

.cp-round:after {
  border-radius: 50%;
  content: " ";
  width: 48px;
  height: 48px;
  display: inline-block;
  box-sizing: border-box;
  border-top: solid 6px $theme-color;
  border-right: solid 6px transparent;
  border-bottom: solid 6px transparent;
  border-left: solid 6px transparent;
  position: absolute;
  top: 0;
  left: 0;
  animation: spin 1s ease-in-out infinite;
}

.image-search-container {
  top: 9px;
  right: 45px;
  z-index: 10;
  cursor: pointer;
  position: absolute;
  background: #fff;
  height: 24px !important;
}

.image-search-result {
  width: 100%;
  padding: 20px;
  border-radius: 2px;
  margin-bottom: 20px;
  display: inline-block;
  border: 1px solid #0041ff;
  background-color: rgba(0, 65, 255, 0.1);

  .searched-image {
    float: left;

    img {
      width: 150px;
      height: auto;
      box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.32);
    }
  }

  .searched-terms {
    margin-left: 20px;
    display: inline-block;

    .term-list a {
      padding: 5px 8px;
      margin-top: 10px;
      background: #fff;
      margin-right: 10px;
    }
  }
}

.filtered-tags {
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
