.footer {
    width: 100%;
    background-color: #ffffff;
    display: inline-block;

    .footer-content {
        .newsletter-subscription {
            color: white;
            padding: 10px 130px;
            background-color: #4d7ea8;

            .newsletter-wrapper {
                input.subscribe-field {
                    width: 300px;
                    border: none;
                    height: 38px;
                    font-size: 18px;
                    max-width: 250px;
                    padding: 10px 20px;
                    color: rgba(0,0,0,0.83);
                }

                button.subscribe-btn {
                    left: -2px;
                    height: 38px;
                    font-size: 18px;
                    line-height: 10px;
                    position: relative;
                }

                .social-icons {
                    height: 100%;
                    padding: 20px 0;
                    color: $white-color;

                    i {
                        margin: 0;
                        cursor: pointer;
                    }

                    .within-circle {
                        background: #4d7ea8;
                        margin-right: 2px;
                        border: 1px solid rgba(255, 255, 255, 0.52);
                    }

                    .within-circle:hover {
                        opacity: 0.5;
                    }

                    img {
                        background: #4d7ea8;
                        border: 1px solid rgba(255, 255, 255, 0.52);
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }

                .subscribe-newsletter {
                    text-align: right;
                    padding: 25px 0 30px 0;
                }
            }
        }

        > .row {
            padding: 60px 130px;
            background: #30383F;

            .logo {
                width: auto;
                max-height: 40px;
            }

            .footer-ct-content {
                > div {
                    margin: 0;
                    padding: 0;
                    font-size: 14px;
                    line-height: 2.5rem;

                    ul {
                        margin-bottom: 0px;

                        li {
                            margin-bottom: 5px;

                            a {
                                color: rgba(255,255,255,0.83);
                            }
                        }
                    }
                }
            }

            .footer-rt-content {
                padding-right: 0px;

                .row {
                    > div {
                        width: 100%;
                        display: block;
                    }

                    .bg-image {
                        width: 42px;
                        height: 30px;
                        display: inline-block;
                        background-position: left;
                    }

                    .bg-image:not(:last-child) {
                        margin-right: 3px;
                    }

                    .cash {
                        background-image: url('../images/static/cash.png');
                    }
                    .cheque {
                        width: 57px !important;
                        background-image: url('../images/static/cheque.png');
                    }
                    .visa {
                        background-image: url('../images/static/visa.png');
                    }
                    .master-card {
                        background-image: url('../images/static/master-card.png');
                    }
                    .paypal {
                        background-image: url('../images/static/paypal.png');
                    }
                    .discover {
                        background-image: url('../images/static/discover.png');
                    }
                }

                .row:not(:last-child) {
                    padding-bottom: 20px;
                }

                h3 {
                    font-size: 14px;
                    color: rgba(255,255,255,0.52);
                }
            }
        }

        .footer-statics {
            .software-description {
                padding-left: 0px;

                p {
                    @extend .clr-dark;

                    font-size: 14px;
                }
            }
        }
    }

    .top-brands {
        padding: 30px 130px;

        .top-brands-body {
            ul {
                width: 85%;
                display: inline-block;

                li {
                    margin-left: 0;
                    font-size: 16px;
                    padding: 15px 0 0 0;
                    display: inline-block;
                }
            }
        }
    }

    .footer-copy-right {
        width: 100%;
        height: 60px;
        font-size: 16px;
        line-height: 6rem;
        text-align: center;
        background: #30383F;;
        color: rgba(255,255,255,0.83);

        p {
            padding: 0px 20px;
        }

        a {
            color: unset;
        }

        a:hover {
            color: $link-color;
        }
    }

}