.vc-small-screen {
  display: none !important;
}

@media only screen and (max-width: 1192px) {
  .sticky-header {
    display: block !important;
  }

  .vc-full-screen {
    display: block !important;
  }

  .vc-small-screen {
    display: none !important;
  }

  #main-category {
    display: block !important;
  }

  .footer {
    .footer-content {
      .newsletter-subscription {
        .newsletter-wrapper {
          .social-icons {
            width: 100%;
            padding: 5px 0;
            text-align: center !important;
          }

          .subscribe-newsletter {
            width: 100%;
            padding: 10px 0;
            text-align: center;
          }
        }
      }

      .footer-statics {
        > div:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }
  }

  .slider-container {
    min-height: 290px;
  }
}

@media only screen and (max-width: 992px) {
  $header-height: 50px;

  body {
    &.open-hamburger {
      color: #7f7f7f;
      opacity: 0.8;
      overflow: hidden;
    }
  }

  #webheader {
    display: none !important;
    position: fixed;
    background-color: $white-color;
  }

  #main-category {
    display: none !important;
  }

  #home-right-bar-container {
    position: relative;
  }

  .sticky-header {
    display: none !important;
  }

  .vc-full-screen {
    display: none !important;
  }

  .vc-small-screen {
    display: block !important;
  }

  .force-center {
    margin: 0 auto !important;
  }

  .main-content-wrapper {
    z-index: 100;
    margin-bottom: 25px;
    background-color: $white-color;

    .vc-header {
      top: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      background-color: grey;
      //background-color: $black-color;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

      > div {
        display: none;
      }

      > div.vc-small-screen {
        display: block;

        img {
          width: 100%;
          height: 100%;
          max-height: $header-height;
        }

        .hamburger-wrapper {
          display: inline-block;
          height: $header-height;

          .hamburger {
            top: 12px;
            font-size: 24px;
            position: relative;
          }
        }

        .right-vc-header {
          position: relative;
          z-index: 2;
          display: table;
          text-align: right;
          height: $header-height;

          > a {
            display: table-cell;
            vertical-align: middle;
          }

          .badge-wrapper,
          .badge-container {
            top: -32px;
            left: -12px;
            position: relative;

            .badge {
              z-index: 10;
              border-radius: 50%;
              position: absolute;
              color: hsla(0, 0%, 100%, 0.83);
              background: $theme-color;
            }
          }

          .badge-container {
            left: 4px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  // #top {
  //   display: none;
  // }

  .product-card-new {
    max-width: 19rem;

    &.grid-card {
      .card-body {
        .product-name {
          width: 13rem;
        }

        .product-rating {
          display: none;
        }

        .add-to-cart-btn {
          padding: 0;
          display: table;

          .btn-add-to-cart {
            .small-padding {
              &.btn-add-to-cart {
                padding: 3px 14px !important;
              }
            }
          }

          ~ a {
            position: relative;

            &.compare-icon {
              right: 0;
            }

            &.wishlist-icon {
              padding: 0;
              left: 10px;
              max-width: 25px;
            }
          }
        }
      }

      #quick-view-btn-container {
        display: none;
      }
    }
  }

  .advertisement-four-container {
    .offers-ct-panel {
      padding: 8px 0;

      a:first-child {
        padding-bottom: 10px !important;
      }
    }
  }

  .advertisement-three-container {
    .top-container,
    .bottom-container {
      img {
        padding: 0px;
        height: unset;
      }
    }

    .second-panel {
      padding-top: 10px;
    }
  }

  .advertisement-two-container {
    a:nth-of-type(2) {
      padding: 15px 0 0 0;
    }
  }

  .category-with-custom-options {
    display: none;

    &.vc-small-screen {
      display: block;

      .smart-category-container {
        .col-12 {
          padding: 0;
        }
      }

      .smart-category-container:not(:first-child) {
        padding-top: 20px;
      }
    }
  }

  .footer {
    .footer-content {
      .newsletter-subscription {
        padding: 10px 20px;

        .newsletter-wrapper {
          margin: 0;
          padding: 0;

          input.subscribe-field {
            width: 200px;
          }

          .subscribe-newsletter {
            text-align: left;

            .subscriber-form-div {
              text-align: center;
            }
          }
        }
      }

      .footer-statics {
        padding: 30px 50px;
      }

      .footer-copy-right {
        font-size: 14px;
      }
    }
  }

  .popular-categories-container {
    .popular-category-wrapper {
      padding: 0px;

      .card {
        .category-image {
          height: 100%;
        }
      }

      &:last-child {
        padding-left: 0;
      }
    }
  }

  .slides-container {
    .VueCarousel {
      .VueCarousel-pagination {
        button {
          width: 5px !important;
          height: 5px !important;
        }

        .VueCarousel-dot {
          padding: 2px !important;
        }
      }
    }
  }

  .account-content {
    .sidebar {
      display: none;
    }

    .account-layout {
      padding: 0;

      &.right {
        padding-right: 20px !important;
        padding-left: 20px !important;
      }

      .account-items-list {
        &.wishlist-container {
          .product-card-new {
            width: calc(50% - 5px);
          }
        }
      }

      .sale-container {
        .tabs-content {
          .totals {
            .sale-summary {
              width: 100%;
              font-size: 17px;

              tbody {
                tr {
                  td {
                    width: 50% !important;

                    &:last-child {
                      text-align: right;
                    }
                  }
                }
              }
            }
          }
        }

        .order-box-container {
          .box {
            width: 100%;
            margin-bottom: 20px;

            .box-title {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }

  .mini-cart-btn {
    display: none;
  }

  header {
    .vc-small-screen {
      .searchbar {
        padding-left: 20px !important;
        padding-right: 20px !important;

        .compare-btn,
        .wishlist-btn {
          display: none;
        }
      }

      #search-form {
        background: transparent;
        width: 100%;

        .selectdiv {
          display: none;

          + input {
            width: calc(100% - 40px);
            border: 1px solid $theme-color;
          }
        }
      }
    }
  }

  .carousel-products {
    &.vc-full-screen {
      display: none;
    }

    &.vc-small-screen {
      display: block !important;
    }

    + .recently-viewed {
      top: 0;
      position: static;
    }
  }

  .reviews-container {
    .review-wrapper,
    .review-wrapper:nth-of-type(1),
    .review-wrapper:nth-last-of-type(1),
    .review-wrapper:nth-last-of-type(2) {
      padding: 0px;
    }

    .review-wrapper:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .product-policy-wrapper {
    padding: 0px !important;
  }

  .product-policy-wrapper:not(:last-child) {
    margin-bottom: 10px;
  }

  .product-detail {
    #product-form {
      .form-container {
        div.left {
          top: 0px;
          position: relative;
          margin-bottom: 20px;

          .vc-small-product-image {
            width: 100%;
          }
        }
      }
    }

    .customer-rating {
      > .row {
        > div {
          margin-bottom: 30px;
        }
      }
    }

    .arrow {
      &.left,
      &.right {
        display: none;
      }
    }

    .thumb-list {
      .thumb-frame {
        > .bg-image {
          background-size: contain;
        }
      }
    }
  }

  .review-page-container {
    > div {
      padding: 0;
    }

    > div:not(:last-child) {
      position: relative;
      margin-bottom: 60px;
    }
  }

  .customer-rating {
    > .row {
      > div:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .auth-content {
    &.form-container {
      > .container {
        margin: 0;
        width: 100%;

        > div:first-child {
          padding: 0;

          .body {
            padding: 20px;
          }
        }
      }
    }
  }

  .category-page-wrapper {
    .layered-filter-wrapper {
      display: none;
    }

    .category-container {
      margin: 0;
      margin-top: 20px;
      padding-left: 0px !important;
      padding-right: 0px !important;

      > div {
        padding: 0 10px;

        &:first-child {
          padding: 0 10px !important;
        }
      }

      .filters-container {
        left: 0px;
        top: 30px;
        padding: 0;
        width: 100%;
        z-index: 9;
        position: fixed;
        padding-bottom: 10px;
        background-color: $white-color;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);

        .toolbar-wrapper {
          > div.col-4 {
            margin: 0;
            padding: 0;
            display: table;
            text-align: center;

            * {
              display: table-cell;
              vertical-align: middle;
            }

            a {
              text-align: center;
              display: inline-block;
            }

            span {
              left: 5px;
              position: relative;
            }
          }
        }
      }
    }
  }

  .nav-container {
    top: 0px;
    left: 0px;
    width: 75%;
    opacity: 1;
    z-index: 9999;
    height: 100vh;
    position: fixed;
    font-size: 16px;
    overflow-y: scroll;
    box-shadow: 0 2px 8px 0;
    background-color: $white-color;

    .wrapper {
      position: relative;

      .category-title {
        width: 100%;
        display: none;
        display: table;
        padding-left: 10px;
        margin: 13px 0px 13px 0px;

        > i {
          font-size: 26px;
          display: table-cell;
          vertical-align: middle;
        }

        span {
          font-size: 20px;
          display: table-cell;
          vertical-align: top;

          i {
            float: left !important;
            margin: 2px 2px 0px 0px !important;
          }
        }
      }

      .greeting {
        top: 0;
        width: 100%;
        display: table;
        position: sticky;
        color: $black-color;
        background-color: $white-color;
        border-bottom: 1px solid $border-common;

        > i {
          font-size: 26px;
          display: table-cell;
          vertical-align: middle;
        }

        span {
          font-size: 20px;
          display: table-cell;
          vertical-align: top;
        }
      }

      ul {
        font-weight: 600;
        color: $black-color;
        border-top: 1px solid $border-common;

        li {
          font-size: 16px;
          padding: 10px 0px 10px 20px;

          a {
            // padding: 10px 0px 10px 20px;
          }

          &:hover {
            background-color: $border-light;
          }

          .category-logo,
          .language-logo-wrapper {
            width: 18px;
            height: 18px;
            margin-right: 5px;
            display: inline-block;
          }

          .rango-arrow-right {
            float: right;
            font-size: 20px;
            padding-top: 5px;
            padding-right: 15px;
          }

          .nested-category {
            border-top: unset;

            li:last-child {
              padding-bottom: 0;
            }
          }
        }

        &:nth-of-type(1) {
          border-top: unset;
        }
      }

      .vc-customer-options,
      .category-wrapper {
        li {
          font-size: 14px;

          i {
            &.icon {
              @include rango-default;

              font-size: 18px;
              padding-right: 5px;
              display: contents;

              &.profile::before {
                content: "\E995";
              }

              &.address::before {
                content: "\e949";
              }

              &.reviews::before {
                content: "\e97d";
              }

              &.wishlist::before {
                content: "\e93e";
              }

              &.compare::before {
                content: "\e93b";
              }

              &.orders::before {
                content: "\e931";
              }

              &.downloadables::before {
                content: "\e926";
              }
            }
          }
        }
      }
    }

    .drawer-section {
      padding: 15px;
    }

    .header {
      &.drawer-section {
        width: 100%;
        display: table;

        > * {
          display: table-cell;
          vertical-align: middle;
        }

        i {
          width: 25px;
          padding-right: 10px;
        }
      }
    }

    .layered-filter-wrapper {
      width: 100%;
      display: block;
      padding-top: 0;
      margin-bottom: 0;
    }
  }

  .category-container,
  .search-container {
    .grid-card {
      width: 45%;
    }

    .grid-card:nth-child(odd) {
      float: left;
    }

    .grid-card:nth-child(even) {
      float: right;
    }
  }

  .cart-details {
    &.offset-1 {
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .order-summary-container {
      &.offset-1 {
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }

    h1,
    .cart-details-header {
      padding: 0;
    }

    h1 {
      margin-bottom: 20px;
    }

    .cart-header {
      display: none;
    }

    .cart-item-list {
      > div {
        margin: 0;
        padding: 0;
      }
    }

    .product-price {
      span:nth-child(1),
      .special-price {
        font-size: 18px;
      }
    }

    .actions {
      margin-top: 7px !important;
    }

    .continue-shopping,
    .empty-cart-message {
      padding: 0;
    }
  }

  .checkout-process {
    margin-left: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    h1,
    > div {
      padding: 0;
    }

    .billing-address {
      margin-bottom: 20px;
    }
  }

  .address-holder {
    > div {
      padding-right: 0;
      padding-bottom: 15px;
    }
  }

  .wishlist-container {
    @include remove-padding-margin;

    .product-card-new {
      margin-left: 0;
    }
  }

  .compare-products {
    padding: 0 !important;

    .col,
    .col-2 {
      max-width: unset;
    }
  }

  .compare-icon,
  .wishlist-icon {
    margin-left: 0;
  }

  .image-search-result {
    .searched-terms {
      margin-left: 0;
      margin-top: 20px;

      .term-list {
        a {
          line-height: 40px;
        }
      }
    }
  }

  #sort-by.sorter select {
    top: 2px;
    left: 25px;
    padding: 0 10px;
    position: absolute;
    display: inline-block;
  }

  .slider-container {
    min-height: 220px;
  }
}

@media only screen and (max-width: 768px) {
  .sticky-header {
    display: none !important;
  }

  #home-right-bar-container {
    position: unset;
    top: unset;
  }

  .modal-container {
    left: 10%;
    max-width: 80%;
    margin-left: 0px;
  }

  .footer .footer-list-container {
    padding-left: 0px !important;
  }

  .footer .currency {
    display: block !important;
  }

  button.btn.btn-sm.btn-primary.apply-filter {
    margin-top: 10px;
  }

  .quick-view-btn-container span {
    left: 24%;
    top: -24px;
    font-size: 13px;
  }

  .quick-view-in-list {
    display: none;
  }

  .product-card-new {
    max-width: 18rem;
  }

  .slider-container {
    min-height: 220px;
  }
}

@media only screen and (max-width: 460px) {
  .product-card-new {
    max-width: 11rem;

    .product-image-container img {
      min-height: unset;
    }
  }
}

@media only screen and (max-width: 420px) {
  .sticky-header {
    display: none !important;
  }

  #home-right-bar-container {
    position: unset;
    top: unset;
  }

  .slider-container {
    min-height: 100px;
  }

  .advertisement-four-container {
    min-height: 992px;

    .advertisement-container-block {
      min-height: 425px;
    }

    .offers-ct-panel {
      min-height: 425px;
    }
  }

  .product-card-new {
    max-width: 9rem;

    .product-image-container img {
      min-height: unset;
    }
  }
}

@media only screen and (max-width: 360px) {
  .product-card-new {
    max-width: 8rem;

    .product-image-container img {
      min-height: unset;
    }
  }
}

@media only screen and (max-width: 320px) {
  body {
    min-width: 280px;
  }

  .sticky-header {
    display: none !important;
  }

  #home-right-bar-container {
    position: unset;
    top: unset;
  }

  .quick-view-in-list {
    display: none;
  }

  .slider-container {
    min-height: 100px;
  }

  .advertisement-four-container {
    min-height: 992px;

    .advertisement-container-block {
      min-height: 425px;
    }

    .offers-ct-panel {
      min-height: 425px;
    }
  }

  .product-card-new {
    max-width: 7rem;

    .product-image-container img {
      min-height: unset;
    }
  }
}
