.carousel {
  @apply mt-8 -mr-3 -ml-3 cursor-grab  active:cursor-grabbing;

  .VueCarousel-dot-container {
    @apply mt-0 #{!important};

    .VueCarousel-dot {
      @apply mt-0 focus:outline-1 focus:outline-themeColor-500 #{!important};
    }
  }
}

//modificatori in base allo sfondo
.bgdark {
  .carousel {
    ul {
      @apply bg-black;

      li {
        @apply text-white;
      }
    }
  }
}

.copertine-carousel {
  @apply p-4;

  .VueCarousel-slide {
    position: relative;
    padding-bottom: 130%;
  }

  .VueCarousel-dot {
    @apply m-0 #{!important};
  }
}
