/*
|
| Added back again because in chrome this is not using and light house also saying not used.
| But in mozilla all fonts are gone which creating problem.
|
*/
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&display=swap");

/* main imports */
@import "main/rango";
@import "main/icons";
@import "main/mixins";
@import "main/variables";

/* shared components */
@import "components/shared";

/* components */
@import "components/UI";
@import "components/app";
@import "components/home";
@import "components/footer";
@import "components/product-view";
@import "components/media";
@import "components/rtl";
@import "components/datagrid";

/* static */
@import "static/material-icons";

/* vendor */
// @import "vendor/tiny-slider.scss";

/* starting */
* {
  margin: 0;
  padding: 0;
  font-family: $font-family-pro;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d8d8d8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #666666;
}

*::-webkit-input-placeholder {
  font-family: $font-family-pro;
}

*::-webkit-input-placeholder {
  font-family: $font-family-pro;
}

input[type="checkbox"] {
  width: 24px;
  height: 15px;
  margin-right: 10px;
}

.form-control {
  &:focus {
    @include box-shadow(0px 0px 8px 1px rgba(105, 221, 157, 0.25));
  }
}

button,
input,
optgroup,
select,
textarea {
  font-family: $font-family-pro;
  color: $font-color;
}

textarea {
  resize: none;
}

html {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-weight: 400;
  color: $font-color;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  background: $white-color;
  font-family: $font-family-pro;
}

label {
  margin: 0;
}

.btn {
  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
    outline-offset: 0px;
  }

  &:active:hover {
    outline: none;
    outline-offset: 0px;
  }
}

.btn-link {
  color: $font-color;
  padding: 6px 5px;
}

.btn-link:hover,
.btn-link:focus {
  color: $font-color;
  text-decoration: none;
}

#top {
  @include box-shadow(0 0 0 0 rgba(0, 0, 0, 0.24));

  margin: 0;
  min-height: 32px;
  color: $font-color;
  border-bottom: 1px solid $border-common;

  .btn {
    font-family: $font-family-pro;
    font-size: 14px;
    letter-spacing: 0;
    text-align: center;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: none;
      outline-offset: 0px;
    }

    &:active:hover {
      outline: none;
      outline-offset: 0px;
    }
  }

  .btn-normal {
    background: $button-primary-bg;
    border-color: $border-primary;
    color: $white-color;
    font-weight: 600;

    &:hover {
      background: $white-color;
      border-color: $button-primary-bg;
      color: $button-primary-bg;
    }

    &:active:hover {
      background: $white-color;
      border-color: $button-primary-bg;
      color: $button-primary-bg;
    }

    &:active:focus {
      background: $white-color;
      border-color: $button-primary-bg;
      color: $button-primary-bg;
    }
  }

  .btn-link {
    color: $font-color;
  }

  .dropdown-menu-large {
    min-width: 250px;
    left: -100px;
  }

  .customer-name {
    font-size: 16px;
    font-weight: 600;
    padding: 0px 10px;
    color: $font-color;
  }

  #account {
    font-size: 14px;

    .select-icon {
      top: 0px;
      left: 0px;
      padding-left: 5px;
    }

    .dropdown-list {
      width: 150px;
    }

    .welcome-content {
      @extend .unselectable;

      display: table;
      min-width: 150px;
      cursor: pointer;
      float: right;
      text-align: right;
      padding-top: 5px;

      * {
        display: table-cell;
        vertical-align: middle;
      }
    }

    .dropdown-list {
      top: 50px;
      right: 0px;

      .modal-header {
        padding: 20px;
      }

      .content {
        padding: 5px 20px 15px 20px;
      }

      .modal-footer {
        .theme-btn {
          width: 50%;
          text-align: center;
        }
      }
    }
  }

  > div:last-child {
    height: 32px;
  }

  > div {
    .default {
      padding: 5px;
      font-size: 14px;
    }
  }

  .locale-icon {
    width: 20px;
    display: inline-block;

    img {
      width: 100%;
    }
  }

  .locale-switcher {
    padding-left: 5px;
    position: relative;
    padding-right: 15px;
    text-align: left;
    cursor: pointer;
  }

  .dropdown {
    margin-right: 15px;

    .select-icon-container {
      .select-icon {
        right: 0;
      }
    }
  }
}

.dropdown-menu {
  border-top: 3px solid $border-primary;
  border-radius: 0px;
  background: $white-color;
  @include box-shadow(11px 10px 17px 0 rgba(0, 0, 0, 0.21));

  li {
    a {
      &:hover {
        background: $button-primary-bg;
        color: $white-color;
      }

      &:focus {
        background: $button-primary-bg;
        color: $white-color;
      }

      .dropdown-menu {
        li {
          a {
            &:focus {
              background: $button-primary-bg;
              color: $white-color;
            }
          }
        }
      }
    }
  }
}

.no-padding {
  padding: 0px !important;
}

.btn-normal {
  background: $button-primary-bg;
  border-color: $border-primary;
  color: $white-color;
  font-weight: 600;
  @include border-radius(0px);

  &:hover {
    background: $white-color;
    border-color: $button-primary-bg;
    color: $button-primary-bg;
  }

  &:active:hover {
    background: $white-color;
    border-color: $button-primary-bg;
    color: $button-primary-bg;
  }

  &:active:focus {
    background: $white-color;
    border-color: $button-primary-bg;
    color: $button-primary-bg;
  }
}

.btn-secondary {
  background: $white-color;
  border-color: $white-color;
  color: $button-primary-bg;

  &:hover {
    background: $button-primary-bg;
    border-color: $button-primary-bg;
  }

  &:focus {
    background: $button-primary-bg;
    border-color: $button-primary-bg;
  }

  &:active:hover {
    background: $button-primary-bg;
    border-color: $button-primary-bg;
  }

  &:active:focus {
    background: $button-primary-bg;
    border-color: $button-primary-bg;
  }
}

.btn-danger {
  background: $button-danger;
  border-color: $border-danger;
  color: $white-color;

  &:hover {
    background: $button-danger;
    border-color: $border-danger;
  }

  &:focus {
    background: $button-danger;
    border-color: $border-danger;
  }

  &:active:hover {
    background: $button-danger;
    border-color: $border-danger;
  }

  &:active:focus {
    background: $button-danger;
    border-color: $border-danger;
  }
}

header {
  #search-form {
    height: 40px;
    margin: 8px 0px;
    background: $white-color;

    * {
      height: 100%;
    }

    .btn-group {
      max-width: 550px;

      .selectdiv {
        width: 210px;

        .select-icon {
          top: -30px;
          right: 8px;
          z-index: 10;
          font-size: 18px;
          background-color: $white-color;
          height: 20px;
        }
      }

      select {
        width: 100%;
        height: 100%;
        cursor: pointer;
        border-radius: 2px 0px 0px 2px;
        border: 1px solid $theme-color;
        border-right: 0;
        font-family: "Source Sans Pro", sans-serif;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      select::-ms-expand {
        display: none;
      }
    }

    input {
      @include border-radius(0px);

      height: 100%;
      font-size: 14px;
      padding: 0 10px;
      line-height: 20px;
      letter-spacing: 0;
      border: 1px solid $theme-color;
      border-left: 1px solid $border-common;
    }

    .btn {
      &:hover {
        text-decoration: none;
      }

      &:focus {
        outline: none;
        outline-offset: 0px;
      }

      &:active:hover {
        outline: none;
        outline-offset: 0px;
      }
    }

    #header-search-icon {
      min-width: 40px;
      border-radius: 0px 2px 2px 0px;
      background-color: $theme-color;

      i {
        color: $button-text-color;
      }
    }
  }

  .left-wrapper {
    float: right;

    .compare-btn,
    .wishlist-btn,
    .mini-cart-btn {
      font-size: 18px;
      font-weight: 600;
      margin: 16px;
      cursor: pointer;
      display: inline-block;

      &.mini-cart-btn {
        margin-right: 0;
      }

      i {
        margin-right: 5px;
        vertical-align: middle;
      }

      .badge-container {
        position: relative;
        display: inline-block;

        .badge {
          @include border-radius(50%);

          top: -23px;
          left: -15px;
          padding: 4px;
          min-width: 20px;
          position: absolute;
          color: $font-color-light;
          background: $button-primary-bg;
        }
      }

      span {
        position: relative;
        padding-left: 0;
      }

      #mini-cart {
        padding: 0;
        line-height: inherit;

        .mini-cart-content {
          font-size: 16px;
          font-weight: 600;
          text-align: right;
          margin-right: 7px;
          letter-spacing: 0;
          position: relative;
          color: $font-color;
          display: inline-block;

          i {
            + span.cart-text {
              padding-left: 0px;
              vertical-align: text-bottom;
            }
          }

          + .down-arrow-container {
            top: 0;

            .rango-arrow-down {
              top: 0;
            }
          }
        }
      }
    }
  }

  .dropdown-menu-large {
    min-width: 280px;
    left: -180px;

    .dropdown-content {
      width: 100%;
      max-height: 300px;
      overflow-y: auto;

      .item {
        display: flex;
        padding: 10px;

        .item-image {
          position: relative;

          .material-icons {
            position: absolute;
            left: -6px;
            top: -6px;
            font-size: 16px;
            cursor: pointer;
          }

          .thumbnail {
            width: 75px;
            height: 75px;
            margin: 0px;
            border-radius: 0px;
            border: 1px solid $border-common;
          }
        }

        .item-name {
          font-weight: 600;
          font-size: 18px;
          color: $font-color;
          letter-spacing: 0;
        }

        .item-details {
          padding: 0px 10px;
          height: auto;

          .item-options {
            font-family: $font-family-pro;
            font-size: 13px;
            color: $font-color;
            letter-spacing: 0;
          }

          .item-qty-price {
            padding: 5px 0px;
            display: inline-block;

            .item-qty {
              font-size: 16px;
              color: $font-color;
              letter-spacing: 0;
              text-align: left;
            }

            .item-price {
              font-weight: 600;
              font-size: 16px;
              color: $font-color;
              letter-spacing: 0;
              text-align: right;
            }
          }
        }
      }
    }

    .dropdown-header {
      padding: 10px 10px 5px;
      border-top: 1px solid $border-common;

      .sub-total-text {
        font-weight: 600;
        font-size: 16px;
        color: $font-color;
        letter-spacing: 0;
      }

      .cart-sub-total {
        font-weight: 700;
        font-size: 16px;
        color: $font-color;
        letter-spacing: 0;
        text-align: right;
      }
    }

    .dropdown-footer {
      padding: 10px 10px 0px 10px;
      border-top: 1px solid $border-common;
      font-weight: 700;
      font-size: 16px;
      color: $font-color;
      letter-spacing: 0;

      .cart-link {
        text-align: left;

        a {
          vertical-align: middle;
        }
      }

      .checkout-link {
        text-align: right;
      }
    }
  }
}

#nav-menu {
  margin: 0;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.24);
  background-color: #ffffff;

  .navbar {
    margin: 0px;
    font-family: SourceSansPro-Semibold;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.83);
    letter-spacing: 0;
    cursor: pointer;
    min-height: 40px;
    position: relative;

    .navbar-header {
      width: 100%;
      display: inline-block;

      .main-category {
        width: 100%;
        overflow: hidden;
        position: relative;
        display: inline-block;
        padding: 5px 5px 5px 35px;

        .material-icons {
          position: absolute;
          left: 0;
          top: 2px;
          font-size: 28px;
        }
      }
    }

    .category-dropdown {
      position: absolute;
      top: 40px;
      background: #fff;
      left: 0;
      width: 100%;
      height: 525px;

      li.category-list {
        width: 100%;
        display: inline-block;
        background: #fff;
        position: relative;

        a {
          padding: 10px 0px;
          position: relative;
          font-weight: 600;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.83);
          letter-spacing: 0;
          font-weight: 600;
          display: block;

          .material-icons {
            position: absolute;
            right: 0;
            top: 8px;
          }

          &:hover {
            color: #28557b;
            text-decoration: none;
            background-color: $light-background;
          }
        }

        .child-container {
          position: absolute;
          top: 0;
          background-color: #ccc;
          left: 283px;
          width: 250px;
          height: 350px;
        }
      }
    }
  }

  .secondary-navbar {
    background-color: #4d7ea8;
    min-height: 40px;
    padding: 5px;
    vertical-align: middle;
    text-align: left;
    margin: 0;
    list-style: none;
    height: auto;
    display: inline-block;
    width: 100%;

    li {
      float: left;

      a {
        display: block;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        position: relative;
        color: $white-color;
        text-decoration: none;
        padding: 5px 20px 5px 5px;
      }
    }
  }
}

.viewed-products .viewed-products-listing {
  border: 1px solid white;
  background-color: #f6f6f6;
}

.viewed-products .viewed-products-listing .product-image {
  display: inline-block;
}

.viewed-products .viewed-products-listing .product-description {
  display: inline-block;
}

.viewed-products .viewed-products-listing .product-description div {
  padding-top: 2px;
}

.customer-reviews .first-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.customer-reviews .second-row {
  width: 100%;
  display: inline-block;
}

.customer-reviews .second-row .reviews-listing {
  background: #ffffff;
  padding-right: 10px;
  -webkit-box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.11);
  box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.11);
}

.customer-reviews .second-row .review-grid {
  display: grid;
  width: 345px;
  height: 262px;
  padding-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.categories-grid-customizable .category-grid {
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 10px;
}

.categories-grid-customizable .category-grid .category-image {
  border: 1px solid red;
}

.categories-grid-customizable .category-grid .category-details {
  border: 1px solid blue;
}

.categories-grid-customizable .category-grid .category-details h3 {
  color: #ffffff;
  text-align: center;
}

.categories-grid-customizable .category-grid .category-details li {
  color: #ffffff;
  text-align: center;
  list-style-type: none;
}

.product-policy {
  padding: 30px 0px 50px 0px;
  border: 1px solid maroon;
  text-align: center;
}

.popular-products {
  height: auto;
  width: 100%;
  padding-right: 10px;
}

.popular-products .second-row .popular-products-listing {
  border: 1px solid red;
}

.popular-products .second-row .popular-products-listing .product-buttons .add-to-cart-button .btn-primary {
  border: #26a37c !important;
  border-radius: 0px;
}

.popular-products .second-row .popular-products-listing .product-buttons .add-to-cart-button .addtocart {
  text-transform: uppercase;
  background-color: #26a37c;
}

.customer-name {
  display: table-cell;
  height: 54px;
  width: 56px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  background: $button-primary-bg;
  color: #fff;
  padding: 16px;
  font: 18px "josefin sans", arial;
}

.spacing {
  margin: 5px 0;
}

i.within-circle {
  display: inline-block;
  border-radius: 50%;
  box-shadow: 0px 0px 2px #888;
  padding: 12px;
  margin: 15px 0px 15px 0px;
  width: 50px;
  height: 50px;
}

.center_div {
  margin: 0 auto;
  width: 80%;
  /* value of your choice which suits your alignment */
}

.form-style {
  display: block;
  width: 100%;
  height: 36px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: rgba(0, 0, 0, 0.83);
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdcdc;
  border-radius: 0;
}

.label-style {
  display: inline-block !important;
  max-width: 100% !important;
  margin-bottom: 5px !important;
  font-weight: 100 !important;
  font-size: 16px !important;
}

.btn-white {
  color: white;
  height: 36px;
  width: 133px;
}

.w3-card-2 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  float: right;
  height: 36px;
  width: 133px;
}

.w3-card-login {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  float: right;
  height: 36px;
  width: 71px;
}

.btn-new-customer-login {
  color: #26a37c !important;
  font-size: 16px;
  padding: 11px;
  text-decoration: none !important;
}

.btn-dark-green {
  color: #fff;
  background-color: #26a37c;
  border-color: #26a37c;
  height: 36px;
  border-radius: 0px !important;
}

.login-text {
  height: 65px;
  width: 575px;
  border: 1px #e5e5e5;
  margin: 0 auto;
}

.row:before,
.row:after {
  display: none !important;
}

// image wrapper
.image-wrapper {
  margin-bottom: 20px;
  margin-top: 10px;
  display: inline-block;
  width: 100%;

  .image-item {
    width: 150px;
    height: 150px;
    margin-right: 20px;
    background: #f8f9fa;
    @include border-radius(3px);
    display: inline-block;
    position: relative;
    background-image: url("../images/placeholder-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 20px;
    float: left;
    background-size: 75%;

    img.preview {
      width: 100%;
      height: 100%;
    }

    input {
      display: none;
    }

    .remove-image {
      background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.24) 100%);
      @include border-radius(0 0 4px 4px);
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 10px;
      text-align: center;
      color: $white-color;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.24);
      margin-right: 20px;
      cursor: pointer;
    }

    &:hover .remove-image {
      display: block;
    }

    &.has-image {
      background-image: none;
    }
  }
}

.btn-primary {
  background-color: #26a37c !important;
  border-color: #26a37c !important;
}

/* Category Filter UI Responsivenes */
.category-page-wrapper .category-container .filters-container {
  left: 0;
  top: 30px;
  padding: 0;
  width: 96%;
  z-index: 9;
  position: unset;
  margin-left: 7px;
  padding-bottom: 7px;
  background-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.filters-container .toolbar-wrapper > div select {
  cursor: pointer;
  padding: 6px 8px;
  color: rgba(0, 0, 0, 0.83);
  background-color: #fff;
}

.filters-container .toolbar-wrapper > div {
  margin: 0 8px 0 0;
}

@media (max-width: 600px) {
  .selective-div {
    width: 97px;
    -webkit-appearance: none;
  }

  .nav-container {
    top: 0px;
    left: 0px;
    width: 75%;
    position: fixed !important;
    opacity: 1;
    z-index: 9999;
    height: 100vh;
    font-size: 16px;
    overflow-y: scroll;
    box-shadow: 5px 0 5px -5px #333;
    background-color: #ffffff;
  }
}

.velocity-divide-page .right {
  width: 99% !important;
}

.main-content-wrapper .content-list ul {
  width: 101.2% !important;
}

.show-password {
  margin-top: 10px !important;
}

/*tailwind*/
@import "tailwind";
