/*
|--------------------------------------------------------------------------
| Velocity Variables
|--------------------------------------------------------------------------
|
| Below are all the variables used in Velocity's SCSS.
|
*/
/* theme colors */
$theme-color: #efc622;
$theme-dark-color: #000000;

/* background colors */
$light-background: #f7f7f9;

/* font colors */
$font-color: rgba(0, 0, 0, 0.83);
$font-color-light: rgba(255, 255, 255, 0.83);

/* button colors */
$button-danger: #f05153;
$button-primary-bg: #21a179;
$button-text-color: #ffffff;

/* border colors */
$border-common: #cccccc;
$border-danger: #f05153;
$border-dark: #dcdcdc;
$border-general: #e5e5e5;
$border-light: #ececec;
$border-primary: #269c77;

/* link colors */
$link-color: #4d7ea8;
$light-link-color: #28557b;

/* remaining colors */
$black-color: #111111;
$danger-color: #f05153;
$grey-color: rgb(158, 158, 158);
$light1-black: #141516;
$light2-black: #cfcfd0;
$white-color: #ffffff;

/* other stuffs */
$sidebar-width: 230px;
$box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
$material-icons-font-path: "../material-icons/iconfont/" !default;
$border-normal: 1px solid $border-dark;
$font-family-pro: "Cairo", sans-serif;

$control-border-color: #c7c7c7;
$selection-color: rgba(0, 64, 255, 0.6);
$brand-color: #0041ff;
