.main-container-wrapper {
  header {
    @apply sticky top-0 z-[999] flex w-full flex-col bg-black py-0;

    .welcome-content {
      @apply py-3 text-white;
    }
  }

  #top {
    @apply border-b-0;

    #account {
      .welcome-content {
        @apply text-white;

        * {
          @apply px-1;
        }

        .material-icons {
          @apply text-themeColor-500;
        }
      }
    }
  }
}

.dropdown {
  @apply absolute z-50 flex w-full justify-end py-4 lg:hidden;

  .menu-mobile {
    @apply hidden;

    span.type {
      @apply mb-2 block font-bold uppercase text-white;
    }

    ul {
      @apply text-white;
      li {
        @apply mb-2;
      }
    }
  }

  &.open {
    .menu-mobile {
      @apply absolute top-full -mt-2 flex w-full bg-neutral-900 py-4 px-6 sm:mt-0;

      a {
        @apply underline hover:text-themeColor-500;
      }
    }
  }
}
