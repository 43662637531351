.ltr {
  direction: ltr;
}
.rtl {
  direction: rtl;
}

.padding-10 {
  padding: 15px;
}
.padding-15 {
  padding: 15px;
}

.fw5 {
  font-weight: 500;
}
.fw6 {
  font-weight: 600;
}
.fw7 {
  font-weight: 700;
}

.fs13 {
  font-size: 13px !important;
}
.fs14 {
  font-size: 14px;
}
.fs15 {
  font-size: 15px;
}
.fs16 {
  font-size: 16px;
}
.fs16i {
  font-size: 16px !important;
}
.fs17 {
  font-size: 17px;
}
.fs18 {
  font-size: 18px;
}
.fs19 {
  font-size: 19px;
}
.fs20 {
  font-size: 20px;
}
.fs24 {
  font-size: 24px;
}
.fs30 {
  font-size: 30px;
}
.fs40 {
  font-size: 40px;
}

.pt0 {
  padding-top: 0px !important;
}
.pt10 {
  padding-top: 10px !important;
}
.pt15 {
  padding-top: 15px !important;
}
.pt20 {
  padding-top: 20px !important;
}

.pl0 {
  padding-left: 0px !important;
}
.pl5 {
  padding-left: 5px !important;
}
.pl15 {
  padding-left: 15px !important;
}
.pl10 {
  padding-left: 10px !important;
}
.pl20 {
  padding-left: 20px !important;
}
.pl30 {
  padding-left: 30px !important;
}
.pl40 {
  padding-left: 40px !important;
}

.pr0 {
  padding-right: 0px !important;
}
.pr5 {
  padding-right: 5px !important;
}
.pr15 {
  padding-right: 15px !important;
}
.pr40 {
  padding-right: 40px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}
.pb10 {
  padding-bottom: 10px !important;
}
.pb15 {
  padding-bottom: 15px !important;
}
.pb30 {
  padding-bottom: 30px !important;
}

.mt5 {
  margin-top: 5px !important;
}
.mt10 {
  margin-top: 10px;
}
.mt15 {
  margin-top: 15px !important;
}

.mr5 {
  margin-right: 5px;
}
.mr7 {
  margin-right: 7px;
}
.mr10 {
  margin-right: 10px;
}
.mr15 {
  margin-right: 15px;
}
.mr20 {
  margin-right: 20px;
}

.mb5 {
  margin-bottom: 5px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb25 {
  margin-bottom: 25px;
}
.mb30 {
  margin-bottom: 30px;
}

.ml0 {
  margin-left: 0px !important;
}
.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px !important;
}
.ml15 {
  margin-left: 15px !important;
}
.ml30 {
  margin-left: 30px !important;
}

.w-0 {
  width: 0px !important;
}
.w-5 {
  width: 5px !important;
}
.w-10 {
  width: 10px !important;
}
.w-15 {
  width: 15px !important;
}

.body-blur {
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

.no-margin {
  margin: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

.flex-wrap {
  flex-wrap: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-default {
  cursor: default;
}

.grey {
  color: rgb(158, 158, 158);
}
.clr-light {
  color: rgba(0, 0, 0, 0.53);
}
.clr-dark {
  color: rgba(255, 255, 255, 0.52);
}
.font-clr {
  color: $font-color;
}

.display-inbl {
  display: inline-block !important;
}
.display-block {
  display: block !important;
}

.align-vertical-top {
  vertical-align: top;
}
.align-vertical-middle {
  vertical-align: middle;
}

.full-width {
  width: 100%;
}

.full-image {
  width: 100%;
  height: 100%;
}

.full-back-size {
  background-size: 100% 100% !important;
}

.max-width-100 {
  max-width: 100% !important;
}

.no-border {
  border: none !important;
}

.back-pos-rt {
  background-position: right;
}

.theme-btn {
  z-index: 10;
  border: none;
  cursor: pointer;
  font-weight: 600;
  padding: 10px 20px;
  vertical-align: top;
  border: 1px solid transparent;
  color: $white-color;
  background-color: $theme-color;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid $theme-dark-color;
    background-color: $theme-color;
  }

  &.light {
    color: $theme-color;
    background-color: $white-color;
    box-shadow: 0 1px 0 0 #cfcfcf;
    border: 1px solid rgba(0, 0, 0, 0.12);

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid $theme-dark-color;
      background-color: #f5f5f5;
    }
  }
}

.theme-btn:hover,
.btn-add-to-cart:hover {
  border-color: $theme-dark-color;
  background-color: $theme-dark-color;
  text-decoration: none;

  &.light {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
}

.norm-btn {
  border: 1px solid $border-common;
  font-size: 14px;
  padding: 9px 20px;
  border-radius: 2px;
  vertical-align: top;
  color: $black-color !important;
  background-color: $white-color !important;
}

.sale-btn {
  z-index: 10;
  border: none;
  color: white;
  font-size: 14px;
  padding: 3px 10px;
  position: absolute;
  border-radius: 12px;
  background-color: $theme-color;
}

.bg-image {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}

.material-icons {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
}

.unselectable {
  * {
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */

    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;
  }
}

.card-arrow-container {
  .card-arrow {
    z-index: 10;
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    background-color: #2b2b2b;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -webkit-box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05), 0 2px 7px 1px rgba(39, 44, 48, 0.16);
    box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05), 0 2px 7px 1px rgba(39, 44, 48, 0.16);
  }

  .card-arrow-tp {
    left: 50%;
    top: -10px;
  }

  .card-arrow-rt {
    top: 50%;
    right: -10px;
  }

  .card-arrow-bt {
    left: 50%;
    top: calc(100% - 10px);
  }

  .card-arrow-lt {
    top: 50%;
    left: -7px;
  }
}

.lg-card-container {
  cursor: pointer;

  a {
    color: $font-color;
    text-decoration: none;
  }

  #quick-view-btn-container {
    :hover {
      color: $white-color !important;
    }
  }

  .background-image-group {
    background-size: contain !important;
  }

  &.grid-card,
  &.list-card {
    .wishlist-icon i {
      padding-left: 10px;
    }

    .product-price span:first-child,
    .product-price span:last-child {
      font-size: 18px;
      font-weight: 600;
    }

    .card-current-price {
      font-size: 18px;
    }

    .product-rating {
      .stars {
        display: inline-block;
      }

      span {
        vertical-align: middle;
      }
    }

    .product-information {
      > div:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    img {
      width: 100%;
    }
  }

  &.list-card {
    margin-left: 0px;
    padding-left: 0px;

    .background-image-group {
      height: 100%;
    }

    .product-image {
      float: left;
      width: 30%;
      height: 270px;
      max-width: 200px;
      max-height: 200px;
      position: relative;

      .quick-view-btn-container {
        button {
          left: calc(50% - 40px);
        }
      }
    }

    .product-information {
      width: 70%;
      float: right;
      padding-left: 20px;
    }

    .product-rating {
      .stars {
        display: inline-block;
      }

      span {
        vertical-align: top;
      }
    }

    .product-information {
      height: 200px;
      display: table;

      > div {
        display: table-cell;
      }
    }

    .product-price {
      .sticker {
        display: block;
      }
    }

    .wishlist-icon {
      height: 40px;
      vertical-align: top;
      display: inline-table;
      padding-left: 0 !important;

      i {
        display: table-cell;
        vertical-align: middle;
        padding-left: 0 !important;
      }
    }

    .compare-icon {
      padding-left: 0;
      display: inline-table;
    }

    .add-to-cart-btn {
      float: left;
      display: inline-block;
    }
  }

  &.grid-card {
    padding: 15px;

    .product-image {
      max-height: 350px;
      max-width: 280px;
      margin-bottom: 10px;
      background: #f2f2f2;

      img {
        display: block;
        height: 100%;
      }
    }
  }

  &.list-card:not(:first-child) {
    margin-top: 20px;
  }
}

.small-padding {
  padding: 3px 4px !important;
}

.medium-padding {
  padding: 3px 10px !important;
}

.general-container {
  cursor: pointer;
}

.lg-card-container {
  > .product-card {
    border: none;
  }
}
.product-card-new:hover,
.lg-card-container:hover,
.general-container:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.lg-card-container:hover {
  .quick-view-btn-container {
    display: block;
  }
}

.text-nowrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #555555;
}

.small-card-container {
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 0 !important;
  margin-right: 0 !important;

  .material-icons {
    font-size: 16px;
  }

  .product-image-container {
    padding: 0;
    display: inline-block;
  }

  .product-image {
    @extend .bg-image;

    width: 70px;
    height: 70px;
    background-position: center;
  }

  .card-body {
    width: 50%;
    display: inline-block;
    padding: 10px 0 !important;

    .product-name {
      font-size: 18px;
      color: #000000;
      width: 100%;
      margin-bottom: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .sticker,
  .regular-price {
    display: none;
  }
}

.small-card-container:hover {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.text-down-3 {
  top: 3px;
  position: relative;
}
.text-down-4 {
  top: 4px;
  position: relative;
}
.text-down-6 {
  top: 6px;
  position: relative;
}

.text-up-1 {
  top: -1px;
  position: relative;
}
.text-up-4 {
  top: -4px;
  position: relative;
}
.text-up-14 {
  top: -14px;
  position: relative;
}

ul.circle-list {
  padding-top: 10px;
  text-align: center;

  li.circle {
    width: 10px;
    height: 10px;
    cursor: pointer;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #d8d8d8;

    &.fill {
      background: #d8d8d8;
    }
  }

  li.circle:not(:last-child) {
    margin-right: 6px;
  }
}

.hide {
  display: none;
}

.category-breadcrumb {
  font-size: 16px;
}

.link-color {
  color: $link-color;
}

a {
  &.unset {
    color: unset !important;
    text-decoration: none !important;
  }

  &.active-hover {
    &:hover {
      color: $link-color !important;
      text-decoration: underline !important;
    }
  }

  &.remove-decoration {
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none !important;
    }
  }
}

.dropdown-icon::after {
  display: inline-block;
  margin-left: 1rem;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

// input:focus,
// select:focus,
// .disable-box-shadow,
// .disable-box-shadow:focus,
// .disable-box-shadow:active {
//   outline: none !important;
//   box-shadow: none !important;
//   -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
//   -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
//   -o-box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
//   box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
// }

.control-error {
  color: $danger-color;
}

.mandatory,
.required {
  width: 100%;

  &::after {
    content: "*";
    font-size: 16px;
    margin-left: -1px;
    color: $danger-color;
  }
}

a {
  &.default {
    color: $font-color !important;
    text-decoration: none !important;
  }
}

.VueCarousel {
  width: 100%;

  .VueCarousel-inner {
    padding-top: 5px;
  }

  .VueCarousel-slide:nth-of-type(1) {
    .product-card-new {
      margin-left: 5px;
    }
  }

  .VueCarousel-navigation {
    .VueCarousel-navigation-prev {
      left: 10px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='enable-background:new 0 0 511.999 511.999' viewBox='0 0 511.999 511.999'%3E%3Cpath d='M172.548 256.005 388.82 39.729c9.089-9.089 9.089-23.824 0-32.912s-23.824-9.089-32.912.002L123.18 239.551a23.26 23.26 0 0 0-6.817 16.454 23.275 23.275 0 0 0 6.817 16.457l232.727 232.721c4.543 4.544 10.499 6.816 16.455 6.816s11.913-2.271 16.457-6.817c9.089-9.089 9.089-23.824 0-32.912L172.548 256.005z' style='fill:%23000'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      text-indent: -9999px;
      width: 25px;
      height: 25px;
      // background-color: whitesmoke;
      // border-top: 15px solid whitesmoke;
      // border-bottom: 15px solid whitesmoke;
      background-position: center;
      outline: none !important;

      &:hover {
        color: #efc622;
      }
    }

    .VueCarousel-navigation-next {
      right: 12px;
      background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='enable-background:new 0 0 512.001 512.001' viewBox='0 0 512.001 512.001'%3E%3Cpath d='M388.819 239.537 156.092 6.816c-9.087-9.089-23.824-9.089-32.912.002-9.087 9.089-9.087 23.824.002 32.912l216.27 216.266-216.273 216.276c-9.087 9.089-9.087 23.824.002 32.912A23.195 23.195 0 0 0 139.636 512a23.194 23.194 0 0 0 16.457-6.817L388.819 272.45a23.27 23.27 0 0 0 0-32.913z' style='fill:%23000'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      text-indent: -9999px;
      width: 25px;
      height: 25px;
      // background-color: whitesmoke;
      // border-top: 15px solid whitesmoke;
      // border-bottom: 15px solid whitesmoke;
      background-position: center;
      outline: none !important;

      &:hover {
        color: #efc622;
      }
    }

    span {
      font-size: 32px;
    }
  }
}

.bgdark {
  .VueCarousel-navigation-prev {
    left: -27px !important;
    top: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' class='bi bi-chevron-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E") !important;
    background-color: #000000 !important;
    border-top: 15px solid #000000 !important;
    border-bottom: 15px solid #000000 !important;
    background-size: cover;
    -webkit-transform: scaleX(-1) !important;
    transform: scaleX(-1) !important;
    margin-top: -27px !important;
  }

  .VueCarousel-navigation-next {
    right: 12px;

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' class='bi bi-chevron-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E") !important;
    background-color: #000000 !important;
    border-top: 15px solid #000000 !important;
    border-bottom: 15px solid #000000 !important;
    background-size: cover;
  }
}

.navigation-hide {
  .VueCarousel-navigation {
    display: none;
  }
}

.pagination-hide {
  .VueCarousel-pagination {
    display: none;
  }
}

.scrollable {
  max-height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollable::-webkit-scrollbar {
  width: 0 !important;
}

button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.max-sm-img-dimension {
  max-width: 110px;
  max-height: 110px;

  img {
    width: 100%;
  }
}

.max-width {
  width: 1440px !important;
  margin: 0 auto !important;
}

.styled-select {
  appearance: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */

  + .select-icon-container {
    position: relative;

    .select-icon {
      top: -24px;
      left: unset;
      right: 10px;
      font-size: 16px;
      position: absolute;
      pointer-events: none;
    }
  }
}

.down-arrow-container {
  position: relative;
  color: $font-color;
  vertical-align: top;
  display: inline-block;

  .rango-arrow-down {
    top: 10px;
    left: -5px;
    font-size: 16px;
    position: absolute;
  }
}

.select-icon {
  top: 5px;
  left: -7px;
  font-size: 16px;
  position: relative;
}

.normal-text {
  color: $light1-black;
}
.normal-white-text {
  color: $font-color-light;
}

.display-table {
  display: table;

  .cell {
    display: table-cell;
    vertical-align: middle;
  }
}

.rango-default {
  speak: none;
  line-height: 1;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  font-family: "Webkul Rango" !important;
}

.max-height-350 {
  max-height: 350px;
}

.border-normal {
  border: $border-normal;
}

.has-error {
  input,
  select,
  textarea {
    border-color: $border-danger !important;
  }
}

.modal-parent {
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1001;
}

.compare-icon,
.wishlist-icon {
  height: 38px;
  display: table;
  cursor: pointer;
  margin-left: 10px;

  i {
    display: table-cell;
    vertical-align: middle;
  }
}
