@import '../main/mixins';
@import '../main/_variables';

.table {
    width: 100%;

    .table-responsive {
        overflow-x: auto;
        width: 100%;

        &::-webkit-scrollbar {
            height: 5px !important;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(200, 200, 200, 1) !important;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px !important;
            background-color: #fff !important;
            -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7) !important;
        }
    }

    table {
        border-collapse: collapse;
        text-align: left;
        width: 100%;

        thead th {
            font-weight: 700;
            padding: 12px 10px;
            background: #f8f9fa;
            color: $font-color;
            border-right: 1px solid #ccc !important;

            &.sortable {
                cursor: pointer;
            }

            &:last-child {
                border-right: none;
            }
        }

        tbody td {
            padding: 10px;
            border-bottom: solid 1px #d3d3d3;
            color: $font-color;
            vertical-align: top;

            &.actions {
                .action {
                    display: inline-flex;
                }

                .icon {
                    display: block;
                    cursor: pointer;
                }
            }

            &.empty {
                text-align: center;
            }
        }

        tbody tr:last-child td {
            border-bottom: none;
        }
    }

    .control-group {
        width: 100%;
        margin-bottom: 0;
        min-width: 140px;

        .control {
            width: 100%;
            margin: 0;
        }
    }
}

.grid-container {
    display: block;
    width: 100%;

    .grid-top {
        display: grid;
        grid-template-rows: auto auto auto;
        row-gap: 8px;
        align-items: center;

        .datagrid-filters {
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 10px;

            .grid-right {
                justify-self: end;
                display: grid;
                grid-template-columns: auto auto;
                column-gap: 10px;
                align-items: end;
            }

            .dropdown-filters {
                display: inline-block;

                &.per-page {
                    .control-group {
                        margin-bottom: 0px;

                        label {
                            margin-top: 7px;
                            margin-right: 10px;
                            flex: auto;
                        }

                        .control {
                            width: 100%;
                            margin: 0;
                            flex: 1;
                        }
                    }
                }
            }
        }
    }

    .datagrid-filters {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 10px;
        align-items: end;
        position: relative;

        .filter-right {
            justify-self: end;
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 10px;
            align-items: end;

            .control-group {
                margin-bottom: 10px;

                .control {
                    margin-bottom: 0px;
                }
            }

            .dropdown-list {
                right: 0;
                padding: 15px;

                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                }

                .apply-filter {
                    width: 100%;
                }
            }
        }
    }
}

.filtered-tags {
    display: inline-flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.search-filter {
    border-radius: 3px;
    height: 36px;
    max-width: 300px;
    position: relative;

    .control {
        font-size: 15px;
        border: 1px solid $control-border-color;
        border-right: none;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        height: 36px;
        width: calc(100% - 36px);
        padding-left: 10px;
        -webkit-appearance: none;
    }

    &:hover {
        box-shadow: 0px 0px 0px 1px $selection-color;
    }

    .contorl:focus {
        border-color: $brand-color;
    }

    .icon-wrapper {
        border: 1px solid $control-border-color;
        border-radius: 3px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        height: 36px;
        width: 36px;
        padding: 5px;
        right: 0px;
        top: 0px;
        position: absolute;
    }
}

.search-icon {
    background-image: url(../images/icon-search.svg);
    width: 24px;
    height: 24px;
  }
  .icon {
    display: inline-block;
    background-size: cover;
  }

.grid-dropdown-header {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    width: 100%;
    min-width: 200px;
    border: 1px solid $control-border-color;
    border-radius: 3px;
    background-color: $white-color;
    padding: 0px 5px 0px 5px;

    .arrow-icon-down {
        float: right;
    }
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-list {
    width: 200px;
    margin-bottom: 20px;

    @include box-shadow(
        0 2px 4px 0 rgba(0, 0, 0, 0.16),
        0 0 9px 0 rgba(68, 58, 58, 0.16)
    );

    @include border-radius(3px);

    background-color: $white-color;
    position: absolute;
    display: none;
    z-index: 1000;
    text-align: left;

    &.bottom-left {
        top: 42px;
        left: 0px;
    }

    &.bottom-right {
        top: 42px;
        right: 0px;
    }

    &.top-left {
        bottom: 0px;
        left: 42px;
    }

    &.top-right {
        bottom: 0px;
        right: 42px;
    }

    .dropdown-label {
        padding: 8px 12px;
        display: block;
        cursor: default;
        color: rgba(0, 0, 0, 0.53);
        font-size: 18px;
        font-weight: 600;
        border-bottom: 1px solid #c1c2c3;
    }

    .dropdown-container {
        overflow-y: auto;

        label {
            font-size: 15px;
            display: inline-block;
            text-transform: uppercase;
            color: #9e9e9e;
            font-weight: 700;
            padding-bottom: 5px;
        }

        ul {
            margin: 0px;
            padding: 0px;
            list-style-type: none;

            li {
                a {
                    padding: 8px 12px;
                    font-size: 16px;

                    &:link,
                    &:active,
                    &:visited,
                    &:focus {
                        color: $font-color;
                        display: block;
                    }

                    &:hover {
                        background-color: $border-light;
                    }
                }

                .control-group label {
                    color: $font-color;
                    font-size: 15px;
                    font-weight: 500;
                    text-transform: capitalize;
                    width: 100%;
                }
            }
        }

        .btn {
            width: 100%;
            margin-top: 10px;
        }
    }
}

.checkbox-dropdown-list {
    width: 170px !important;
    right: 14px !important;

    input {
        width: unset !important;
    }
}

.dropdown-btn {
    min-width: 150px;
    text-align: left;
    background: #fff;
    border: 1px solid #cccccc;
    border-radius: 3px;
    font-size: 14px;
    padding: 8px 35px 8px 10px;
    cursor: pointer;
    position: relative;
}

.dropdown-btn:focus {
    border-color: #26a37c !important;
    outline: unset !important;
}

.filter-advance {
    display: flex;
    justify-content: space-between;
}

.filter-tag {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    height: 40px;
    border-radius: 2px;
    margin-right: 20px;

    .wrapper {
        margin-left: 4px;
        padding: 5px 10px 5px 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #e7e7e7;
        border: 1px solid #e7e7e7;
        font-size: 14px;
        height: 40px;
        color: #000311;
        letter-spacing: -0.22px;

        @include border-radius(24px);

        .icon.cross-icon {
            margin-left: 10px;
            cursor: pointer;
        }

        &:hover {
            background: $white-color;
            border: 1px solid #e7e7e7;
        }
    }
}

.rtl {
    .grid-container .datagrid-filters .filter-right .dropdown-list {
        right: unset;
    }

    .search-filter {
        .control {
            padding-right: 10px;
            border-left: 0;
            border-right: 1px solid #c7c7c7;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .icon-wrapper {
            float: left;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }

        &:hover {
            box-shadow: 0px 0px 0px 1px $selection-color;
        }
    }

    .dropdown-filters {
        display: inline-block;

        &.per-page {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .filtered-tags {
        .filter-tag {
            .wrapper,
            .cross-icon {
                margin-right: 10px;
                margin-left: 0;
            }
        }
    }

    .checkbox-dropdown-list {
        .dropdown-container {
            text-align: right;
        }

        .checkbox {
            input[type='checkbox'] {
                float: right;
                margin-right: unset;
                margin-left: unset;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .grid-container {
        .grid-top {
            .datagrid-filters {
                grid-template-columns: 100%;
                row-gap: 0px;

                .search-filter {
                    max-width: 100% !important;
                }

                .filter-left {
                    display: grid;
                    grid-template-columns: 49.5% 49%;
                    column-gap: 5px;
                }

                .filter-right {
                    display: grid;
                    grid-template-columns: 49.5% 49%;
                    column-gap: 5px;
                    width: 100%;
                }
            }
        }
    }

    .grid-dropdown-header {
        min-width: 122px;
    }

    .dropdown-list {
        &.dropdown-container {
            padding: 10px;
        }
    }
}
